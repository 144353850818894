import React from 'react'
import PropTypes from 'prop-types'
import styles from './Text.module.scss'
const TEXT_TYPES = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    title: 'title',
    p1: 'p1',
    p2: 'p2',
    p3: 'p3'
}

const H1 = ({children, type, other, handleClick, className}) => (
    // @ts-ignore
    <h1 className={`${styles['txt']} ${styles[type]} ${className}`} other={other} onClick={handleClick}>
        {children}
    </h1>
)
const H2 = ({children, type, other, handleClick, className}) => (
    // @ts-ignore
    <h2 className={`${styles['txt']} ${styles[type]} ${className}`} other={other} onClick={handleClick}>
        {children}
    </h2>
)
const H3 = ({children, type, other, handleClick, className}) => (
    // @ts-ignore
    <h3 className={`${styles['txt']} ${styles[type]} ${className}`} other={other} onClick={handleClick}>
        {children}
    </h3>
)
const H4 = ({children, type, other, handleClick, className}) => (
    // @ts-ignore
    <h4 className={`${styles['txt']} ${styles[type]} ${className}`} other={other} onClick={handleClick}>
        {children}
    </h4>
)

const P = ({children, type, other, handleClick, className}) => (
    // @ts-ignore
    <p className={`${styles['txt']} ${styles[type]} ${className}`} other={other} onClick={handleClick}>
        {children}
    </p>
)

H1.propTypes = {
    children: PropTypes.any,
    other: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TEXT_TYPES)),
    handleClick: PropTypes.func,
    className: PropTypes.string
}
H2.propTypes = {
    children: PropTypes.any,
    other: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TEXT_TYPES)),
    handleClick: PropTypes.func,
    className: PropTypes.string
}
H3.propTypes = {
    children: PropTypes.any,
    other: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TEXT_TYPES)),
    handleClick: PropTypes.func,
    className: PropTypes.string
}
H4.propTypes = {
    children: PropTypes.any,
    other: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TEXT_TYPES)),
    handleClick: PropTypes.func,
    className: PropTypes.string
}
P.propTypes = {
    children: PropTypes.any,
    other: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TEXT_TYPES)),
    handleClick: PropTypes.func,
    className: PropTypes.string
}

export {H1, H2, H3, H4, P}
