import React from 'react'
import PropTypes from 'prop-types'
import styles from './ModalView.module.scss'
import {Image, Modal} from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import confirmationImg from './assets/imgs/confirmation.png'
import GradientBtn from '../gradient-btn/GradientBtn'
import {P} from '../text/Text'
import OutlinedInput from '../outlined-input/OutlinedInput'
import CloseIcon from './assets/icons/close-light.svg'

const MODAL_TYPES = {
    confirmation: 'confirmation',
    success: 'success',
    input: 'input',
    customView: 'customView',
    customViewIframe: 'customViewIframe',
    customViewIframeLarge: 'customViewIframeLarge'
}
const ModalView = ({type, title, description, btn, input, show, setShow, customView, onCancel}) => {
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName={`${styles['modal']} ${type === 'customViewIframeLarge' ? styles['custom-large'] : ''}`}
            contentClassName={`${styles['content']} ${type === 'customViewIframe' ? styles['custom'] : ''}`}
            centered>
            <Modal.Header className={styles['header']}>
                <P type='title' other='title'>
                    {title}
                </P>
                <div className={styles['close-icon-container']} onClick={() => setShow(false)}>
                    <SVG src={CloseIcon} />
                    {/* <CloseIcon /> */}
                </div>
            </Modal.Header>
            <Modal.Body>
                <RenderBody
                    type={type}
                    description={description}
                    input={input}
                    customView={customView}
                    btn={btn}
                    onCancel={onCancel}
                />

                {/* {!customView && <GradientBtn title={btn.title} action={() => btn.action()} type='gradient' />} */}
            </Modal.Body>
        </Modal>
    )
}

export default ModalView

const RenderBody = ({type, description, input, customView, btn, onCancel}) => {
    if (type === 'success') {
        return (
            <div className={styles['body-content']}>
                <Image src={confirmationImg} loading='lazy' className={styles['confirm-img']} />
                <P type='p1' other='description'>
                    {description}
                </P>
                {btn && <GradientBtn title={btn.title} action={() => btn.action()} type='gradient' />}
            </div>
        )
    } else if (type === 'confirmation') {
        return (
            <div className={`${styles['body-content']} ${styles['body-confirmation']}`}>
                {/* <Image src={confirmationImg} loading='lazy' className={styles['confirm-img']} /> */}

                <P type='p1' other='description'>
                    {description}
                </P>
                <div className={styles['action-btns-container']}>
                    <GradientBtn title='الغاء' action={() => onCancel()} type='redGradient' />
                    <GradientBtn
                        title={btn.title}
                        action={() => btn.action()}
                        type='gradient'
                        disabled={btn.disabled}
                    />
                </div>
            </div>
        )
    } else if (type === 'input') {
        return (
            <div className={styles['body-content']}>
                <P type='p1' other='description'>
                    {description}
                </P>

                <OutlinedInput
                    title={input.title}
                    value={input.value}
                    setValue={(e) => input.setValue(e.target.value)}
                    error={input.error}
                />
                <GradientBtn title={btn.title} action={() => btn.action()} type='gradient' />
            </div>
        )
    } else {
        return <div className={styles['custom-view-container']}>{customView}</div>
    }
}

ModalView.propTypes = {
    type: PropTypes.oneOf(Object.keys(MODAL_TYPES)),
    title: PropTypes.string,
    description: PropTypes.string,
    btn: PropTypes.object,
    input: PropTypes.object,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    customView: PropTypes.object,
    onCancel: PropTypes.func
}
