import React from 'react';
import styles from './Upload.module.scss';
import {useDropzone} from 'react-dropzone';
import {useState} from 'react';
import uploadIcon from './assets/icons/upload.svg';
import {Image} from 'react-bootstrap';
import {P} from '../text/Text';
import UploadItem from './upload-item/UploadItem';
import GradientBtn from '../gradient-btn/GradientBtn';
import OutlinedInput from '../outlined-input/OutlinedInput';
import compressImage from '../../helpers/compressImage';
import firebase from 'firebase';
import moment from 'moment';

const uploadString = {
    AR: 'قم بسحب وإسقاط أو تصفح لتحميل الملفات',
    EN: 'Drag and drop or browse to upload files'
};

const sendString = {
    AR: 'ارسال',
    EN: 'Send'
};

const loadingString = {
    AR: 'جار الأرسال...',
    EN: 'Sending'
};

const chooseString = (v) => ({
    AR: `تم اختيار ${v}, اضغط هنا للتغير`,
    EN: `Uploading for ${v}, Click here to change`
});

type localFile = {file: File; name: string; type: string; percent: number};
export type uploadedFile = {image: File; url?: string};
type Props = {
    lang?: 'AR' | 'EN';
    afterUpload: (files: uploadedFile[]) => void;
    mainStorageRef?: firebase.storage.Reference;
    setPreStep?: React.Dispatch<any>;
    preStep?: {
        title?: string;
        show: boolean;
        exist: boolean;
        value?: string;
        options?: string[] | {title: string; value: string}[];
    };
    acceptPDF?: boolean;
};

const Upload = ({
    lang = 'EN',
    afterUpload,
    preStep = {show: false, exist: false},
    setPreStep,
    mainStorageRef,
    acceptPDF = false
}: Props): JSX.Element => {
    const [files, setFiles] = useState<localFile[]>([]);
    const [loading, setLoading] = useState(false);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: `image/jpeg, image/png, ${acceptPDF ? 'application/pdf' : ''}`,
        onDrop: (acceptedFiles: File[]) => {
            setFiles((prev) => [...prev, ...acceptedFiles.map((file) => ({file: file, name: file.name, type: file.type, percent: 0}))]);
            console.log('setFiles');
        }
    });

    const removeFile = (fileToBeDeleted: File) => {
        setFiles((prev) => prev.filter((file) => fileToBeDeleted !== file.file));
    };

    const handleUpload = async () => {
        setLoading(true);

        const arr: uploadedFile[] = [];

        for await (const element of files) {
            if (element.type !== 'application/pdf') {
                const compressedElement = await compressImage(element.file);

                if (typeof mainStorageRef === 'undefined') {
                    arr.push({image: compressedElement, url: undefined});
                } else {
                    await submitUpload(compressedElement, mainStorageRef).then((url) => {
                        arr.push({image: compressedElement, url});
                    });
                }
            } else {
                if (typeof mainStorageRef === 'undefined') {
                    arr.push({image: element.file, url: undefined});
                } else {
                    await submitUpload(element.file, mainStorageRef).then((url) => {
                        arr.push({image: element.file, url});
                    });
                }
            }

            setFiles((prev) => {
                return [
                    ...prev.map((v) => {
                        if (v.file === element.file) {
                            return {...v, percent: 100};
                        } else {
                            return v;
                        }
                    })
                ];
            });
        }

        afterUpload(arr);
        setLoading(false);
    };

    return (
        <div className={styles['upload-container']}>
            {preStep.show ? (
                <div>
                    <OutlinedInput
                        type='select'
                        options={preStep.options}
                        title={preStep.title}
                        value={preStep.value}
                        setValue={(e) => setPreStep({...preStep, value: e.target.value})}
                        lang={lang}
                    />
                </div>
            ) : (
                <>
                    {preStep.exist && (
                        <div className={styles['row']}>
                            <P type='p2' handleClick={() => setPreStep((prev) => ({...prev, value: '', show: true}))}>
                                {chooseString(preStep.value)[lang]}
                            </P>
                        </div>
                    )}
                    <div {...getRootProps()} className={styles['drop-container']}>
                        <input {...getInputProps()} />
                        <Image alt='upload-icon' src={uploadIcon} />
                        <P type='p2'>{uploadString[lang]}</P>
                        {isDragActive}
                    </div>

                    {files.length > 0 &&
                        files.map((file, index) => (
                            <UploadItem
                                file={file.file}
                                name={file.name}
                                type={file.type}
                                percent={file.percent}
                                removeFile={removeFile}
                                key={index}
                            />
                        ))}

                    <GradientBtn
                        title={loading ? loadingString[lang] : sendString[lang]}
                        type='gradient'
                        disabled={files.length === 0 || loading}
                        action={handleUpload}
                    />
                </>
            )}
        </div>
    );
};

export default Upload;

function submitUpload(file: File, ref: firebase.storage.Reference): Promise<string> {
    return new Promise(async (resolve, reject) => {
        ref.child(`${moment().unix()}`)
            .put(file)
            .then((snapshot) => snapshot.ref.getDownloadURL())
            .then((downloadURL: string) => resolve(downloadURL))
            .catch((err: any) => reject(err));
    });
}
