import React from 'react'
import PropTypes from 'prop-types'
import styles from './SectionTitle.module.scss'
import {H1} from '../text/Text'

const SectionTitle = ({title, internal}) => {
    return (
        <div className={`${styles['section-title-container']} ${internal ? styles['internal'] : ''}`}>
            <H1 type='h2'>{title}</H1>
            <hr />
        </div>
    )
}

export default SectionTitle

SectionTitle.propTypes = {
    title: PropTypes.string,
    internal: PropTypes.bool
}
