import React from 'react'
import PropTypes from 'prop-types'
import styles from './EmptyContainer.module.scss'
import {Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import GradientBtn from '../gradient-btn/GradientBtn'
import {H4, P} from '../text/Text'

const EmptyContainer = ({title, description, img, custom}) => {
    return (
        <div className={styles['empty-container']}>
            <div className={styles['empty-content']}>
                <div className={styles['empty-text-container']}>
                    <H4>{title}</H4>
                    <RenderCustomData custom={custom} />
                </div>
                <Image src={img} alt='empty' className={styles['empty-img']} loading='lazy' />
            </div>
        </div>
    )
}

export default EmptyContainer

const RenderCustomData = ({custom}) => {
    if (custom) {
        return (
            <>
                <P type='title'>اذهب لصفحة الدكتور لأختيار موعد</P>
                <Link className={styles['btn-container']} to='/profile'>
                    <GradientBtn title='الدكتور' type='gradient' align='right' />
                </Link>
            </>
        )
    }
    return (
        <>
            <P type='title'>اختار طبيب واحجز موعدك القادم الان</P>
            <Link className={styles['btn-container']} to='/profile'>
                <GradientBtn title='الاطباء' type='gradient' align='right' />
            </Link>
        </>
    )
}

EmptyContainer.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    custom: PropTypes.bool
}
