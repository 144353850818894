import React from 'react'
import PropTypes from 'prop-types'
import styles from './SubHeader.module.scss'
import {H1} from '../text/Text'

const SubHeader = ({title}) => {
    return (
        <div className={styles['sub-header-container']}>
            <H1 type='h2'>{title}</H1>
        </div>
    )
}

export default SubHeader

SubHeader.propTypes = {
    title: PropTypes.string
}
