import React from 'react'
import PropTypes from 'prop-types'
import styles from './UploadItem.module.scss'
import imgIcon from '../assets/icons/img.svg'
import pdfIcon from '../assets/icons/pdf.svg'
import closeIcon from '../assets/icons/close.svg'
import removeIcon from '../assets/icons/remove.svg'
import {Image, ProgressBar} from 'react-bootstrap'
import {P} from '../../text/Text'

const UploadItem = ({file, name, type, percent, removeFile}) => {
    return (
        <div className={styles['upload-item']}>
            <Image
                alt='file-icon'
                className={styles['file-icon']}
                src={type === 'application/pdf' ? pdfIcon : imgIcon}
            />
            <div className={styles['data']}>
                <div className={styles['first-row']}>
                    <P type='p1'>{file.name}</P>
                    <P type='p1' other='percent'>
                        {percent}%
                    </P>
                    {percent !== 100 && (
                        <Image
                            alt='close-icon'
                            className={styles['close-icon']}
                            src={closeIcon}
                            onClick={() => removeFile(file)}
                        />
                    )}
                </div>
                <ProgressBar now={percent} className={styles['progress-bar']} />
            </div>
        </div>
    )
}

export default UploadItem

UploadItem.propTypes = {
    file: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string,
    percent: PropTypes.number,
    removeFile: PropTypes.func
}
