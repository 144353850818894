import React from 'react'
import PropTypes from 'prop-types'
import styles from './Footer.module.scss'
import {Image} from 'react-bootstrap'
import Brand from '../brand/Brand'
import NavLinks from '../nav-links/NavLinks'
import {P} from '../text/Text'
import fbLogo from './assets/logos/fb.svg'
import LinkedInLogo from './assets/logos/linkedin.svg'

const links = [
    {title: 'ماذا نفعل', link: ''},
    {title: 'شركاؤنا', link: ''},
    {title: 'اتصل بنا', link: ''},
    {title: 'حمل التطبيق', link: ''}
]

const Footer = ({custom, app}) => {
    const currentLocation = window.location.pathname

    if (currentLocation === '/404') {
        return null
    }
    return (
        <div className={styles['footer-container']}>
            <Brand custom={custom} title={app && app.title} logo={app && app.logo} />
            <div className={styles['nav-links-container-footer']}>
                <NavLinks links={links} type='footer' />
            </div>
            <div className={styles['social-logos-container']}>
                <a href='https://www.facebook.com/Curapp.net' target='_blanc'>
                    <Image src={fbLogo} loading='lazy' className={styles['social-logo']} alt='social-icon' />
                </a>
                <a href='https://www.linkedin.com/company/curawella' target='_blanc'>
                    <Image src={LinkedInLogo} loading='lazy' className={styles['social-logo']} alt='social-icon' />
                </a>
            </div>
            <hr className={styles['hr']} />

            <P type='p2'>جميع الحقوق محفوظة</P>
        </div>
    )
}

export default Footer

Footer.propTypes = {
    custom: PropTypes.bool,
    app: PropTypes.object
}
