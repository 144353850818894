import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styles from './NavBar.module.scss'
import {Nav, Navbar} from 'react-bootstrap'
import {useHistory, Link, useLocation} from 'react-router-dom'
import SVG from 'react-inlinesvg'
import GradientBtn from '../gradient-btn/GradientBtn'
import Brand from '../brand/Brand'
import NavLinks from '../nav-links/NavLinks'
import ChatIcon from './assets/icons/chat.svg'
import NotificationIcon from './assets/icons/notification.svg'
import UserIcon from './assets/icons/user.svg'

const links = [
    {title: 'الأطباء', link: '/doctors'},
    {title: 'محفظتي', link: '/wallet'},
    {title: 'المواعيد', link: '/appointments'},
    {title: 'السجل الطبي', link: '/medicalrecords'},
    {title: 'اتصل بنا', link: '/contactus'}
    // {title: 'EN', link: '/en'}
]

const customLinks = [
    {title: 'عن الدكتور', link: '/profile'},
    {title: 'محفظتي', link: '/wallet'},
    {title: 'المواعيد', link: '/appointments'},
    {title: 'السجل الطبي', link: '/medicalrecords'},
    {title: 'اتصل بنا', link: '/contactus'}
]
const notAuthLinks = [
    {title: 'عن الدكتور', link: '/profile'},
    {title: 'المقالات', link: '/blogs'},
    {title: 'اتصل بنا', link: '/contactus'}
]

const authPaths = ['/login', '/register', '/404']
const classNames = {
    '/': 'out',
    '/login': 'login-nav',
    '/register': 'login-nav',
    '/404': 'login-nav'
}
const NavBar = ({isAuthenticated, custom, app}) => {
    let currentLocation = useLocation().pathname
    const [whiteLinks, setWhiteLinks] = useState(false)
    const [location, setLocation] = useState(currentLocation)

    useEffect(() => {
        setLocation(currentLocation)
    }, [currentLocation])

    useEffect(() => {
        setLocation(currentLocation)
        setWhiteLinks(authPaths.includes(currentLocation))
    }, [currentLocation])

    return (
        <div
            className={`${styles['nav-bar-container']} ${
                styles[classNames[location]] ? styles[classNames[location]] : styles['in']
            } `}>
            <Navbar expand='lg' className={`${styles.navbar}`} collapseOnSelect>
                <Link to='/'>
                    <Brand custom={custom} title={app && app.title} logo={app && app.logo} />
                </Link>
                <Navbar.Toggle aria-controls='basic-navbar-nav' className={styles['toggle-btn']} />
                <Navbar.Collapse
                    id='basic-navbar-nav'
                    className={`${styles['navbar-collapse']} ${whiteLinks ? styles['white'] : ''}`}>
                    <NavLinks
                        links={!isAuthenticated ? notAuthLinks : custom ? customLinks : links}
                        margin='m-auto'
                        activePath={location}
                        whiteLinks={whiteLinks}
                        type='navBar'
                    />

                    <RenderAuth
                        isAuthenticated={isAuthenticated}
                        whiteLinks={whiteLinks}
                        activePath={location}
                        custom={custom}
                    />
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default NavBar

const RenderAuth = ({isAuthenticated, whiteLinks, activePath, custom}) => {
    const history = useHistory()

    const navigateToLogin = () => {
        history.push('/login')
    }

    if (isAuthenticated) {
        if (custom) {
            return (
                <div className={styles['auth-container']}>
                    <Nav.Link
                        as={Link}
                        eventKey='chat'
                        to='/chat'
                        className={`${styles['auth-icon-container']} ${
                            activePath === '/chat' ? styles['active'] : ''
                        }`}>
                        <SVG src={ChatIcon} className={styles['auth-icon']} />
                    </Nav.Link>

                    <Nav.Link
                        as={Link}
                        eventKey='account'
                        to='/account'
                        className={`${styles['auth-icon-container']} ${
                            activePath === '/account' ? styles['active'] : ''
                        }`}>
                        <SVG src={UserIcon} className={styles['auth-icon']} />
                    </Nav.Link>
                </div>
            )
        }
        return (
            <div className={styles['auth-container']}>
                <Nav.Link
                    as={Link}
                    eventKey='notifications'
                    to='/notifications'
                    className={`${styles['auth-icon-container']} ${
                        activePath === '/notifications' ? styles['active'] : ''
                    }`}>
                    <SVG src={NotificationIcon} className={styles['auth-icon']} />
                </Nav.Link>
                <Nav.Link
                    as={Link}
                    eventKey='chat'
                    to='/chat'
                    className={`${styles['auth-icon-container']} ${activePath === '/chat' ? styles['active'] : ''}`}>
                    <SVG src={ChatIcon} className={styles['auth-icon']} />
                </Nav.Link>

                <Nav.Link
                    as={Link}
                    eventKey='account'
                    to='/account'
                    className={`${styles['auth-icon-container']} ${activePath === '/account' ? styles['active'] : ''}`}>
                    <SVG src={UserIcon} className={styles['auth-icon']} />
                </Nav.Link>
            </div>
        )
    }

    return (
        <Nav.Link to='/login' as={Link} eventKey='login'>
            <GradientBtn
                title='تسجيل الدخول'
                type={whiteLinks ? 'outLinedWhite' : 'gradient'}
                hover={whiteLinks ? 'solidWhite' : 'gradient'}
                // action={() => navigateToLogin()}
            />
        </Nav.Link>
    )
}

NavBar.propTypes = {
    isAuthenticated: PropTypes.bool,
    custom: PropTypes.bool,
    app: PropTypes.object
}

RenderAuth.propTypes = {
    isAuthenticated: PropTypes.bool,
    whiteLinks: PropTypes.bool,
    activePath: PropTypes.string,
    custom: PropTypes.bool
}
