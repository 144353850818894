import React from 'react'
import PropTypes from 'prop-types'
import styles from './GradientBtn.module.scss'
import {Button} from 'react-bootstrap'

const TYPES_HOVER = {
    outLinedPrimary: 'outLinedPrimary',
    outLinedWhite: 'outLinedWhite',
    solidPrimary: 'solidPrimary',
    solidWhite: 'solidWhite',
    gradient: 'gradient',
    redGradient: 'redGradient',
    light: 'light',
    lightRed: 'lightRed'
}

const GradientBtn = ({title, action, direction, align, type, hover, width, className, disabled, name}) => {
    const handleClick = () => {
        if (action) {
            action()
        }
    }
    return (
        <div
            className={`${styles['gradient-btn-container']} ${styles[direction]} ${styles[align]} ${className}`}
            style={width && {width: width}}>
              {/* @ts-ignore */}
            <Button onClick={handleClick} type={type} hover={hover} disabled={disabled && true} name={name}>
                {title}
            </Button>
        </div>
    )
}

export default GradientBtn

GradientBtn.propTypes = {
    title: PropTypes.any,
    action: PropTypes.func,
    direction: PropTypes.string,
    align: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(TYPES_HOVER)),
    hover: PropTypes.oneOf(Object.keys(TYPES_HOVER)),
    width: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string
}
