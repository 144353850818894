import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styles from './FullForm.module.scss'
import {Image} from 'react-bootstrap'
import GradientBtn from '../gradient-btn/GradientBtn'
import OutlinedInput from '../outlined-input/OutlinedInput'
import {H4, P} from '../text/Text'
import {getCountries, getStatesByShort} from 'countrycitystatejson'
import {egGovAr} from '../../constants/AppConstants'
import cameraIcon from './assets/icons/camera.svg'
import ModalView from '../../components/modal-view/ModalView'
import Terms from '../legal-terms/terms'
const FORM_TYPE = {
    patientProfile: 'patientProfile',
    register: 'register'
}

const FullForm = ({
    type,
    user,
    setUser,
    userErrors,
    handleClick,
    newAvatar,
    setNewAvatar,
    legalTerms,
    PrivacyPolicy
}) => {
    const [countries, setCountries] = useState([])
    const [phoneCode, setPhoneCode] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [cities, setCities] = useState([])
    const getFields = (input, field) => {
        let output = []
        for (let i = 0; i < input.length; ++i) output.push(input[i][field])
        return output
    }

    useEffect(() => {
        let countriesAll = getCountries()
        let countriesTmp = getFields(countriesAll, 'native')
        setCountries(countriesTmp)
    }, [])

    useEffect(() => {
        if (user && user.country) {
            let countryIdx = countries.findIndex((tmp) => tmp === user.country)

            if (countryIdx < 0) {
                return
            }

            let countriesAll = getCountries()
            let shortName = countriesAll[countryIdx].shortName
            let countryCode = '+' + countriesAll[countryIdx].phone
            let citiesTmp = shortName.toLowerCase() === 'eg' ? egGovAr : getStatesByShort(shortName)

            setPhoneCode(countryCode)
            setUser({...user, phoneCode: countryCode})
            setCities(citiesTmp)
        }
    }, [countries, user.country])

    // console.log('user in full form: ', user)
    // console.log('test date: ', user && user.bd)
    // console.log('phoneCode: ', phoneCode)

    return (
        <div className={styles['form-container']}>
            {type === 'patientProfile' ? (
                <>
                    <div className={styles['avatar-container']}>
                        <Image loading='lazy' className={styles['avatar']} src={user.avatar} />
                        <div className={styles['change-avatar-container']}>
                            <Image className={styles['change-avatar']} src={cameraIcon} />
                        </div>
                        <input
                            type='file'
                            onChange={(e) => setNewAvatar(e.target.files[0])}
                            accept='.png, .jpg, .jpeg'
                        />
                    </div>
                    <H4>{`${user.firstName} ${user.lastName}`}</H4>
                    <P type='title'>بيانات المستخدم</P>{' '}
                </>
            ) : (
                <H4 other='title'>سجل حسابك وابدأ رحلتك معنا</H4>
            )}
            <div className={styles['inputs-row']}>
                <OutlinedInput
                    title='الأسم الأول'
                    width='48.5%'
                    value={user.firstName}
                    setValue={(e) => setUser({...user, firstName: e.target.value})}
                    disabled={type === 'patientProfile' && true}
                    error={userErrors.firstName}
                    lang='AR'
                />

                <OutlinedInput
                    title='الأسم الأخير'
                    width='48.5%'
                    value={user.lastName}
                    setValue={(e) => setUser({...user, lastName: e.target.value})}
                    disabled={type === 'patientProfile' && true}
                    error={userErrors.lastName}
                    lang='AR'
                />
            </div>
            <div className={styles['input-container']}>
                <OutlinedInput
                    title='رقم الهاتف'
                    value={user.phone}
                    setValue={(e) => setUser({...user, phone: e.target.value})}
                    handleClick={handleClick}
                    name='phone'
                    error={userErrors.phone}
                    lang='AR'
                />
                {type === 'patientProfile' && (
                    <div className={styles['overlay']} onClick={() => handleClick('phone')} />
                )}
                {/* <GradientBtn title='تعديل' type='gradient' action={() => handleClick('phone')} name='phone' /> */}
            </div>
            <div className={styles['input-container']}>
                <OutlinedInput
                    title='البريد الألكترونى'
                    value={user.email}
                    setValue={(e) => setUser({...user, email: e.target.value})}
                    handleClick={handleClick}
                    name='email'
                    error={userErrors.email}
                    lang='AR'
                />
                {type === 'patientProfile' && (
                    <div className={styles['overlay']} onClick={() => handleClick('email')} />
                )}
                {/* <GradientBtn title='تعديل' type='gradient' action={() => handleClick('phone')} name='email' /> */}
            </div>
            {type === 'patientProfile' ? (
                <>
                    <P
                        type='p1'
                        other='change-password'
                        className={styles['change-password']}
                        handleClick={() => handleClick('newPassword')}>
                        تغيير كلمة المرور
                    </P>
                    <P type='title'>معلومات شخصية</P>
                </>
            ) : (
                <OutlinedInput
                    title='كلمة المرور'
                    type='password'
                    value={user.password}
                    setValue={(e) => setUser({...user, password: e.target.value})}
                    error={userErrors.password}
                    lang='AR'
                />
            )}
            <OutlinedInput
                title={'تاريخ الميلاد'}
                type='date'
                value={user.bd}
                setValue={(e) => setUser({...user, bd: e.target.value})}
                error={userErrors.bd}
                lang='AR'
                max='today'
            />
            <div className={styles['gender-container']}>
                <P type='p2'>النوع</P>
                <div className={styles['btns-container']}>
                    <GradientBtn
                        title='ذكر'
                        type={user.gender === '1' ? 'solidPrimary' : 'light'}
                        hover='solidPrimary'
                        width='48%'
                        // disabled={type === 'patientProfile'}
                        action={() => {
                            setUser({...user, gender: '1'})
                        }}
                    />
                    <GradientBtn
                        title='انثي'
                        type={user.gender === '0' ? 'solidPrimary' : 'light'}
                        hover='solidPrimary'
                        width='48%'
                        // disabled={type === 'patientProfile'}
                        action={() => {
                            setUser({...user, gender: '0'})
                        }}
                    />
                </div>
            </div>
            {userErrors.gender && (
                <P type='p2' other='error-text'>
                    * {userErrors.gender}
                </P>
            )}
            {type === 'patientProfile' && (
                <>
                    <OutlinedInput
                        title={'الوزن'}
                        value={user.weight}
                        setValue={(e) => setUser({...user, weight: e.target.value})}
                        name='weight'
                        error={userErrors.weight}
                        lang='AR'
                        type='number'
                        max={100}
                    />

                    <OutlinedInput
                        title={'الطول'}
                        value={user.height}
                        setValue={(e) => setUser({...user, height: e.target.value})}
                        name='weight'
                        error={userErrors.height}
                        lang='AR'
                        type='number'
                    />
                </>
            )}
            <div className={styles['inputs-row']}>
                <OutlinedInput
                    title={'البلد'}
                    type='select'
                    options={countries}
                    width='48.5%'
                    value={user.country}
                    setValue={(e) => {
                        setUser({...user, country: e.target.value, city: ''})
                    }}
                    error={userErrors.country}
                    lang='AR'
                />

                <OutlinedInput
                    title={'المحافظة'}
                    type='select'
                    options={cities}
                    width='48.5%'
                    value={user.city}
                    setValue={(e) => {
                        setUser({...user, city: e.target.value})
                    }}
                    error={userErrors.city}
                    lang='AR'
                />
            </div>
            {/* {userErrors.country && (
                <P type='p2' other='error-text'>
                    {userErrors.country}
                </P>
            )}

            {userErrors.city && (
                <P type='p2' other='error-text'>
                    {userErrors.city}
                </P>
            )} */}
            {type !== 'patientProfile' && (
                <>
                    {/* <div className={styles['check-box-container']}>
                        <input
                            type='checkbox'
                            checked={user.policy}
                            onChange={(e) => setUser({...user, policy: e.target.checked})}
                        />
                        <P type='p2'>لقد قمت بقراءة شروط سياسة الاستخدام</P>
                    </div> */}
                    <div className={styles['check-box-container']}>
                        <input
                            type='checkbox'
                            checked={user.policy}
                            onChange={(e) => setUser({...user, policy: e.target.checked})}
                        />
                        <p>
                            لقد قمت بقراءة
                            <button onClick={(e) => setShowModal(!showModal)}>شروط سياسه الاستخدام</button>
                        </p>
                    </div>
                    <ModalView
                        show={showModal}
                        setShow={setShowModal}
                        type='custom'
                        customView={<Terms legalTermsData={legalTerms} PrivacyPolicy={PrivacyPolicy} />}
                    />
                    {userErrors.policy && (
                        <P type='p2' other='error-text'>
                            {userErrors.policy}
                        </P>
                    )}
                </>
            )}
        </div>
    )
}

export default FullForm

FullForm.propTypes = {
    type: PropTypes.oneOf(Object.keys(FORM_TYPE)),
    user: PropTypes.object,
    setUser: PropTypes.func,
    userErrors: PropTypes.object,
    handleClick: PropTypes.func
}
