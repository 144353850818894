export const egGovAr = [
    'القاهرة',
    'الاسكندرية',
    'طنطا',
    'المنصورة',
    'الإسماعيلية',
    'أسوان',
    'أسيوط',
    'الأقصر',
    'البحيرة',
    'بني سويف',
    'بور سعيد',
    'الغربية',
    'الشرقية',
    'الدقهلية',
    'القليوبية',
    'الجيزة',
    'دمياط',
    'المنوفية',
    'الفيوم',
    'المنيا',
    'سيناء',
    'قناة السويس',
    'البحر الاحمر ',
    'مرسى مطروح',
    'الوادي الجديد',
    'كفر الشيخ',
    'قنا',
    'سوهاج'
]

// export const API = 'http://192.168.0.11:3030'
export const API = process.env.REACT_APP_API_LINK


export const packageName = 'com.curawella'
export const doctorUid = 'uxt2gnngWBhC0Cs4P9Q9wpk6jC43'
export const appId = 'e1ec8403-e146-4bd5-adc1-fcd095646eac'

// export const firebaseConfig = {
//     apiKey: 'AIzaSyAHPrWPDgtdfg2ihSxwQ6PLYAvS_zd19N8',
//     authDomain: 'curawella-development.firebaseapp.com',
//     databaseURL: 'https://curawella-development.firebaseio.com',
//     projectId: 'curawella-development',
//     storageBucket: 'curawella-development.appspot.com',
//     messagingSenderId: '434693192696',
//     appId: '1:434693192696:web:c60e20b4f407f366'
// }

export const paymentMethods = {
    TOKEN: 'TOKEN',
    CARD: 'CARD'
}
export const getWeekDay = {
    5: 'السبت',
    6: 'الأحد',
    0: 'الأثنين',
    1: 'الثلاثاء',
    2: 'الأربع',
    3: 'الخميس',
    4: 'الجمعه'
}

export const getDrLevelEn = {
    GENERAL_PRACTITIONER: 'General Practitioner',
    SPECIALIST: 'Specialist',
    ASSISTANT_LECTURER: 'Assistant Lecturer',
    CONSULTANT: 'Consultant',
    LECTURER: 'Lecturer',
    ASSISTANT_PROFESSOR: 'Assistant professor',
    PROFESSOR: 'Professor'
}

export const getDrLevelAr = {
    GENERAL_PRACTITIONER: 'ممارس عام',
    SPECIALIST: 'أخصائى',
    ASSISTANT_LECTURER: 'محاضر مساعد',
    CONSULTANT: 'استشارى',
    LECTURER: 'محاضر',
    ASSISTANT_PROFESSOR: 'أستاذ مساعد',
    PROFESSOR: 'أستاذ'
}

// export const gender = [
//   { type: 'Female', isChecked: false },
//   { type: 'Male', isChecked: false },
// ];

// export const blogs = [
//   {
//     avatar: require('../images/userAvatar.png'),
//     name: 'Dr. Jihan Mostafa',
//     date: 'Wed, 30 Dec, 2020',
//     title: 'صحة نفسية',
//     details:
//       'الحفاظ علي صحة نفسية جيدة مهم لعيش حياة طويلة وهانئة اذ انها قد تكون سببا رئيسيا لطول العمر علي عكس الصحة النفسية السيئة التي تعيق صاحبها من عيش حياة افضل الحفاظ علي صحة نفسية جيدة مهم لعيش حياة طويلة وهانئة اذ انها قد تكون سببا رئيسيا لطول العمر علي عكس الصحة النفسية السيئة التي تعيق صاحبها من عيش حياة افضل الحفاظ علي صحة نفسية جيدة مهم لعيش حياة طويلة وهانئة',
//     pinned: true,
//   },
//   {
//     avatar: require('../images/userAvatar.png'),
//     name: 'Dr. Jihan Mostafa',
//     date: 'Wed, 30 Dec, 2020',
//     image: [
//       {
//         url: '',
//         props: {
//           source: require('../images/hallucination-0.png'),
//         },
//       },
//     ],
//     title: 'الوسواس القهري',
//     details:
//       'الحفاظ علي صحة نفسية جيدة مهم لعيش حياة طويلة وهانئة اذ انها قد تكون سببا رئيسيا لطول العمر علي عكس الصحة النفسية السيئة التي تعيق صاحبها من عيش حياة',
//     pinned: false,
//   },
//   {
//     avatar: require('../images/userAvatar.png'),
//     name: 'Dr. Jihan Mostafa',
//     date: 'Wed, 30 Dec, 2020',
//     title: 'الوسواس القهري',
//     details:
//       'الحفاظ علي صحة نفسية جيدة مهم لعيش حياة طويلة وهانئة اذ انها قد تكون سببا رئيسيا لطول العمر علي عكس الصحة النفسية السيئة التي تعيق صاحبها من عيش حياة',
//     pinned: false,
//   },
// ];

// export const notificationsList = [
//   {
//     image: require('../images/notification_reminder.png'),
//     type: 'reminder',
//     title: 'Reminder for your appointment',
//     subTitle: 'You have an appointment today with dr. Jihan Mostafa at 7:00 PM.',
//     time: '12:00 PM',
//   },
//   {
//     image: require('../images/chat-active.png'),
//     type: 'message',
//     title: 'Reminder for your appointment',
//     subTitle: 'Dr. Jihan Mostafa has applied to your message.',
//     time: '12:00 PM',
//   },
//   {
//     image: require('../images/notification_successfully.png'),
//     type: 'successful',
//     title: 'Appointment successfully booked',
//     subTitle: 'You have successfully booked your next appointment on Sunday, 22 Apr at 3:00 PM with dr. Karim Magdy',
//     time: '12:00 PM',
//   },
//   {
//     image: require('../images/notification_cancel.png'),
//     type: 'cancel',
//     title: 'Appointment cancelled',
//     subTitle: 'You have cancelled your next appointment with dr. Jihan Mostafa',
//     time: '12:00 PM',
//   },
//   {
//     image: require('../images/notification_successfully.png'),
//     type: 'successful',
//     title: 'Promo Code successfully applied',
//     subTitle: 'Promo Code successfully applied',
//     time: '12:00 PM',
//   },
// ];

// export const MedicalRecordsList = [
//   {
//     date: '22 Mar, 2021',
//     drName: 'Dr. Karim Magdy',
//     drDegree: 'Pulmonologist , General Practitioner',
//     diagnose: 'Diaphragm Paralysis',
//     image: require('../images/lungs.png'),
//   },
//   {
//     date: '22 Mar, 2021',
//     drName: 'Dr. Karim Magdy',
//     drDegree: 'Pulmonologist , General Practitioner',
//     diagnose: 'Diaphragm Paralysis',
//     image: require('../images/brain.png'),
//   },
//   {
//     date: '22 Mar, 2021',
//     drName: 'Dr. Karim Magdy',
//     drDegree: 'Pulmonologist , General Practitioner',
//     diagnose: 'Diaphragm Paralysis',
//     image: require('../images/cardiogram.png'),
//   },
//   {
//     date: '22 Mar, 2021',
//     drName: 'Dr. Karim Magdy',
//     drDegree: 'Pulmonologist , General Practitioner',
//     diagnose: 'Diaphragm Paralysis',
//     image: require('../images/lungs.png'),
//   },
//   {
//     date: '22 Mar, 2021',
//     drName: 'Dr. Karim Magdy',
//     drDegree: 'Pulmonologist , General Practitioner',
//     diagnose: 'Diaphragm Paralysis',
//     image: require('../images/lungs.png'),
//   },
// ];

// export const diagnosisData = [
//   {
//     image: require('../images/report.png'),
//   },
//   {
//     image: require('../images/report.png'),
//   },
// ];

// export const drugsData = [
//   {
//     drugName: 'Vitamin C',
//     dose: '3 times / day',
//     Pills: 'Pills',
//   },
//   {
//     dose: '3 times / day',
//     drugName: 'Vitamin C',
//     Pills: 'Pills',
//   },
// ];

// export const labsData = [
//   {
//     labType: 'PCR',
//   },
//   {
//     labType: 'CBC',
//   },
// ];

// export const scansData = [
//   {
//     scanType: 'Chest CT',
//   },
// ];
