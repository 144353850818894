// ===============||>>>>>>>>>COMPONENTS IMPORT>>>>>>>>>||S===============
import {H1, H2, H3, H4, P} from './components/text/Text';
import BlogCard from './components/blog-card/BlogCard';
import GradientBtn from './components/gradient-btn/GradientBtn';
import Brand from './components/brand/Brand';
import CardItem from './components/card-item/CardItem';
import OutlinedInput from './components/outlined-input/OutlinedInput';
import ContactUsForm from './components/contactus-form/ContactUsForm';
import EmptyContainer from './components/empty-container/EmptyContainer';
import NavLinks from './components/nav-links/NavLinks';
import Footer from './components/footer/Footer';
import FullForm from './components/full-form/FullForm';
import Gallery from './components/gallery/Gallery';
import ModalView from './components/modal-view/ModalView';
import NavBar from './components/navbar/NavBar';
import SectionTitle from './components/section-title/SectionTitle';
import SubHeader from './components/sub-header/SubHeader';
import Upload from './components/upload/Upload';
import compressImage from './helpers/compressImage';

// ===============||>>>>>>>>>HOOKS IMPORTS>>>>>>>>>||===============
import useFetch from './hooks/useFetch';

// ===============||<<<<<<<<<COMPONENTS EXPORTS<<<<<<<<<||===============
export {H1, H2, H3, H4, P};
export {
    BlogCard,
    GradientBtn,
    Brand,
    CardItem,
    OutlinedInput,
    ContactUsForm,
    EmptyContainer,
    NavLinks,
    Footer,
    FullForm,
    Gallery,
    ModalView,
    NavBar,
    SectionTitle,
    SubHeader,
    Upload
};

// ===============||<<<<<<<<<HOOKS EXPORTS<<<<<<<<<||===============
export {useFetch};
export {compressImage};
