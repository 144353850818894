import {Tabs, Tab} from 'react-bootstrap'
// import LegalTerms from './legal-term-component/LegalTerms'
import './terms.module.scss'
import {SubHeader} from '../..'
const Terms = ({legalTermsData, PrivacyPolicy}) => {
    return (
        <>
            <div className='terms'>
                <h3>Legal terms</h3>

                <Tabs defaultActiveKey='Terms & Conditions' id='uncontrolled-tab-example' className='mb-3'>
                    <Tab eventKey='Terms & Conditions' title='Terms & Conditions'>
                        {legalTermsData.termsData.map((term, index) =>
                            term.type === 'header' ? (
                                <h2>{term.value} </h2>
                            ) : term.type === 'paragraph' ? (
                                <p>{term.value} </p>
                            ) : term.type === 'subHeader' ? (
                                <span>{term.value} </span>
                            ) : (
                                ''
                            )
                        )}
                    </Tab>
                    <Tab eventKey='Privacy Policy' title='Privacy Policy'>
                        {PrivacyPolicy.Privacy.map((term, index) =>
                            term.type === 'header' ? (
                                <h2>{term.value} </h2>
                            ) : term.type === 'paragraph' ? (
                                <p>{term.value} </p>
                            ) : term.type === 'subHeader' ? (
                                <span>{term.value} </span>
                            ) : (
                                ''
                            )
                        )}
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default Terms
