import React from 'react';
import styles from './ContactUsForm.module.scss';
import contactUsImg from './assets/imgs/contact.png';
import {Image} from 'react-bootstrap';
import GradientBtn from '../gradient-btn/GradientBtn';
import OutlinedInput from '../outlined-input/OutlinedInput';

const msg = ` 
 
من فضلك اشرح سؤالك او مشكلتك هنا بشكل مفصل لكي نتمكن من تقديم افضل مساعدة لك`;

const ContactUsForm = ({data, setData, errors, setErrors, onSubmit, loading}) => {
    return (
        <div className={styles['contact-us-container']}>
            <div className={styles['form']}>
                <div className={styles['inputs-container']}>
                    <OutlinedInput
                        title='الأسم'
                        width='49%'
                        value={data.name}
                        setValue={(e) => setData({...data, name: e.target.value})}
                        error={errors.name}
                    />
                    <OutlinedInput
                        title='رقم الهاتف'
                        width='49%'
                        value={data.phone}
                        setValue={(e) => setData({...data, phone: e.target.value})}
                        error={errors.phone}
                    />
                </div>
                <OutlinedInput
                    title='موضوع الرساله'
                    type='text'
                    value={data.subject}
                    setValue={(e) => setData({...data, subject: e.target.value})}
                    error={errors.subject}
                />
                <OutlinedInput
                    title='الرسالة'
                    type='textarea'
                    placeholder={msg}
                    value={data.body}
                    setValue={(e) => setData({...data, body: e.target.value})}
                    error={errors.body}
                />
                <GradientBtn title='ارسال' direction='right' type='gradient' action={onSubmit} disabled={loading} />
            </div>
            <Image src={contactUsImg} loading='lazy' className={styles['contact-us-img']} alt='contact-us' />
        </div>
    );
};

export default ContactUsForm;
