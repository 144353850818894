import React from 'react'
import PropTypes from 'prop-types'
import styles from './Brand.module.scss'
import curaLogo from './assets/imgs/cura.png'
import {Image} from 'react-bootstrap'

const Brand = ({custom, title, logo}) => {
    if (custom) {
        return (
            <div className={`${styles['brand-container']} ${styles['custom']}`}>
                <span>{title}</span>
                <Image src={logo} alt='logo' className={styles['logo']} />
            </div>
        )
    }
    return (
        <div className={styles['brand-container']}>
            <Image src={curaLogo} alt='curapp-logo' className={styles['logo']} />
            <span>CurApp</span>
        </div>
    )
}

export default Brand

Brand.propTypes = {
    custom: PropTypes.bool,
    title: PropTypes.string,
    logo: PropTypes.string || undefined
}
