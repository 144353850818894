import React, {useCallback, useState} from 'react'
import axios from 'axios'
import {API} from '../constants/AppConstants'
function useFetch() {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)

    const execute = useCallback((method, url, body) => {
        setLoading(true)
        setData(null)
        setError(null)

        axios({url: `${API}/${url}`, method, data: body})
            .then((res) => {
                setLoading(false)
                const {data} = res
                if (data) {
                    setData(data)
                } else {
                    setData(res)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.response && err.response.data) {
                    setError(err.response.data)
                } else if (err.response) {
                    setError(err.response)
                } else {
                    setError(err)
                }
            })
    }, [])

    return [data, loading, error, execute]
}

export default useFetch
