import imageCompression from 'browser-image-compression';

export interface CompressionOptions {
    /** @default Number.POSITIVE_INFINITY */
    maxSizeMB?: number;
    /** @default undefined */
    maxWidthOrHeight?: number;
    /** @default false */
    useWebWorker?: boolean;
    /** @default 10 */
    maxIteration?: number;
    /** Default to be the exif orientation from the image file */
    exifOrientation?: number;
    /** A function takes one progress argument (progress from 0 to 100) */
    onProgress?: (progress: number) => void;
    /** Default to be the original mime type from the image file */
    fileType?: string;
    /** @default 1.0 */
    initialQuality?: number;
}

const defaultOptions: CompressionOptions = {maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true};

export default async function (image: File, options?: CompressionOptions): Promise<File> {
    const o = typeof options !== 'undefined' ? options : defaultOptions;

    return imageCompression(image, o);
}
